import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';

import { Alert } from '../shared/Alert';
import Button from '../shared/Button';
import { Form, FormGroup, FormInput, FormLabel, FormTextarea } from '../shared/Form';

export default function ContactForm() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [alert, setAlert] = useState(null);

    const form = useRef();

    const handleSubmit = (e) => {
        e.preventDefault();

        const serviceId = process.env.REACT_APP_SERVICE_ID;
        const templateId = process.env.REACT_APP_TEMPLATE_ID;   
        const templateParams = { name, email, message };
        const publicKey = process.env.REACT_APP_PUBLIC_KEY;

        emailjs.send(serviceId, templateId, templateParams, publicKey)
            .then(res => {
                setAlert(<Alert status="success" color="dark">Thank you! Your message has been received!</Alert>);
            })
            .catch(err => {
                setAlert(<Alert status="error" color="light">Your message could not be sent. Please try again!</Alert>);
            });
    }

    return (
        <Form ref={form} onSubmit={handleSubmit}>
            {alert}
            <FormGroup>
                <FormLabel>Name</FormLabel>
                <FormInput 
                    type="text"
                    id="name"
                    placeholder="Your Name"
                    aria-label="Your Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required 
                />
            </FormGroup>
            <FormGroup>
                <FormLabel>Email</FormLabel>
                <FormInput
                    type="email"
                    id="email"
                    placeholder="Your Email"
                    aria-label="Your Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required 
                />
            </FormGroup>
            <FormGroup>
                <FormLabel>Message</FormLabel>
                <FormTextarea
                    id="message"
                    placeholder="Enter your message here..."
                    aria-label="Enter your message here..."
                    rows={6}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    required 
                />
            </FormGroup>
            <Button type="submit" bg="secondary" color="dark" size="md">Send</Button>
        </Form>
    );
}