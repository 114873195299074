import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Topbar } from './Topbar';
import { Footer } from './Footer';

export const TextLink = styled(Link)`
    color: inherit;
    text-decoration: none;
    font-size: var(--font-size-md);

    ${Topbar} &, ${Footer} & {
        font-family: 'IBM Plex Sans', sans-serif;
        font-weight: var(--font-weight-bold);
        text-transform: uppercase;
        color: inherit;
    }
`; 