import styled from 'styled-components';

export const Footer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: calc(var(--gap) * 4);
    background: var(--gray-800);
    color: var(--secondary-500);
`;

export const FooterCopyright = styled.div`
    font-size: calc(var(--font-size) * 0.5);
    text-align: center;
    margin: var(--gap);
    color: var(--light);
`;

export const FooterNav = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: var(--gap);

    @media (min-width: 48rem) {
        flex-direction: row;
        justify-content: space-evenly;
    }
`;

export const FooterNavItem = styled.div`
    margin: 0 calc(var(--gap) * 0.5);
    padding: calc(var(--gap) * 0.5);
    transition: all 0.25s linear;
    border-bottom: 2px solid transparent;
    &:hover {
        border-bottom: 2px solid var(--secondary-500);
    }
`;

export const FooterOutgoing = styled.div`
    display: flex;
`;

export const FooterOutgoingItem = styled.div`
    margin: calc(var(--gap) * 0.5);
    border-bottom: 2px solid transparent;
    transition: all 0.5s linear;
    &:hover {
        border-bottom: 2px solid var(--secondary-500);
    }

    svg {
        fill: var(--secondary-500);
        height: 20px;
        width: 20px;
    }
`;