import styled from 'styled-components';

export const Form = styled.form`
    border-radius: var(--border-radius);
    width: 100%;
    font-family: 'Inter', BlinkMacSystemFont, -apple-system, 'Segoe UI', Helvetica, Arial, sans-serif;
    overflow: hidden;
`;

export const FormHeader = styled.div``;

export const FormGroup = styled.div`
    display: flex;
    flex-direction: column;
    margin: var(--gap) 0;
`;

export const FormLabel = styled.label`
    font-size: 0.875rem;
    margin-bottom: calc(var(--gap) * 0.5);
`;

export const FormInput = styled.input`
    border-radius: var(--border-radius);
    background: transparent;
    border: 1px solid var(--dark);
    width: 100%;
    padding: calc(var(--gap) * 1.25) var(--gap);
    outline: none;
    transition: all 0.35s linear;
    font-family: inherit;
    &::placeholder {
        color: var(--gray-6);
    }
    &:focus {
        background: var(--light);
        color: var(--dark);
    }
`;

export const FormTextarea = styled.textarea`
    border-radius: var(--border-radius);
    background: transparent;
    border: 1px solid var(--dark);
    width: 100%;
    padding: calc(var(--gap) * 1.25) var(--gap);
    outline: none;
    transition: all 0.35s linear;
    font-family: inherit;
    &::placeholder {
        color: var(--gray-6);
    }
    &:focus {
        background: var(--light);
        color: var(--dark);
    }
`;