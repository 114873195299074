import styled from 'styled-components';

export const Alert = styled.div`
    display: inline-flex;
    align-items: center;
    padding: var(--gap);
    margin: calc(var(--gap) * 0.5) 0;
    width: 100%;
    border-radius: var(--border-radius);
    background: var(--${props => props.status}-500);
    color: var(--${props => props.color});

    p {
        margin-bottom: 0;
    }
    
    svg {
        fill: var(--${props => props.color});
        margin-right: var(--gap);
    }
`;

export const AlertGroup = styled.div`
    display: flex;
    flex-direction: column;
`;
