import React from 'react';

import { Container } from '../components/layout/Container';

import ContactForm from '../components/features/ContactForm';
import { Section } from '../components/layout/Section';
import { SectionHeader } from '../components/shared/SectionHeader';

export default function Contact() {
    return (
        <>
            <SectionHeader bg="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_auto,dpr_auto/etienne-beauregard-riverin-B0aCvAVSX8E-unsplash_atc1ia.webp" />
            <Container>
                <Section>
                    <h1>Get into Contact with Us!</h1>
                    <ContactForm /> 
                </Section>
            </Container>
        </>
    );
}