import React from 'react';

import { Footer, FooterCopyright, FooterNav, FooterNavItem } from '../shared/Footer';
import { TextLink } from '../shared/TextLink';

// import { ReactComponent as FacebookIcon } from '../../assets/icons/logo--facebook.svg';
// import { ReactComponent as InstagramIcon } from '../../assets/icons/logo--instagram.svg';
// import { ReactComponent as TwitterIcon } from '../../assets/icons/logo--twitter.svg';

export default function CustomFooter() {
    return (
        <Footer>
            <FooterNavItem>
                <TextLink to="/">Home</TextLink>
            </FooterNavItem>
            <FooterNavItem>
                <TextLink to="/contact">Contact</TextLink>
            </FooterNavItem>
            {/* <FooterOutgoing>
                <FooterOutgoingItem>
                    <FacebookIcon />
                </FooterOutgoingItem>
                <FooterOutgoingItem>
                    <InstagramIcon />
                </FooterOutgoingItem>
                <FooterOutgoingItem>
                    <TwitterIcon />
                </FooterOutgoingItem>
            </FooterOutgoing> */}
            <FooterCopyright>
                <p>&copy; Lobos Realty Investments, LLC 2022</p>
            </FooterCopyright>
        </Footer>
    );
}