import React, { useState } from 'react';

import { Topbar, TopbarNav, TopbarMobileNav, TopbarNavItem, TopbarLogo } from '../shared/Topbar';
import { TextLink } from '../shared/TextLink';

import { ReactComponent as MenuIcon } from '../../assets/icons/menu-icon.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/close-icon.svg';

export default function Navbar() {
    const [open, setOpen] = useState(false);

    const handleClick = () => setOpen(!open);

    return (
        <Topbar role="navigation">
            <TopbarLogo>
                <TextLink to="/">
                    <img src="https://res.cloudinary.com/dwot7f2oz/image/upload/f_auto,w_auto,q_auto,dpr_auto/lobos-logo-gold_o3bhpp.webp" alt="Lobos Realty Investments, LLC Logo" />
                </TextLink>
            </TopbarLogo>
            <TopbarNav open={open}>
                <TopbarNavItem onClick={handleClick}>
                    <TextLink to="/">Home</TextLink>
                </TopbarNavItem>
                <TopbarNavItem onClick={handleClick}>
                    <TextLink to="/contact">Contact</TextLink>
                </TopbarNavItem>
            </TopbarNav>
            <TopbarMobileNav>
                { open ? <CloseIcon onClick={handleClick} /> : <MenuIcon onClick={handleClick} /> }
            </TopbarMobileNav>
        </Topbar>
    );
}