import styled from 'styled-components';

export const Topbar = styled.div`
    z-index: 15;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: var(--gap) calc(var(--gap) * 2);
    background: var(--gray-800);
    color: var(--secondary-500);
    transition: all 0.25s linear;
    
    svg {
        fill: var(--${props => props.color});
        width: calc(var(--font-size) * 1.5);
        height: calc(var(--font-size) * 1.5);
        cursor: pointer;
    }
`;

export const TopbarNav = styled.div`
    @media (max-width: 47.9rem) {
        display: ${props => props.open ? 'flex' : 'none'};
        text-align: center;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        overflow: hidden;
        background: inherit;
    }

    @media (min-width: 48rem) {
        display: flex;
        justify-content: space-evenly;
    }
`;

export const TopbarMobileNav = styled.div`
    z-index: 12;
    position: relative;
    top: 0;
    left: 90;
    transform: translate(-90%, 0%);

    svg {
        fill: var(--secondary-500);
    }

    @media (min-width: 48rem) {
        display: none;
    }
`;

export const TopbarNavItem = styled.div`
    display: inline-flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    padding: var(--gap) 0;
    margin: 0 var(--gap);
    border-bottom: 1px solid transparent;
    &:hover {
        border-bottom: 1px solid var(--secondary-500);
    }
`;

export const TopbarLogo = styled.div`
    img {
        height: 80px;
        width: 90px;
    }
`;