import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
    :root {
        --primary-100: #CF9849;
        --primary-300: #B47D2F;
        --primary-500: #8E6225;
        --secondary-100: #FCEBBF;
        --secondary-300: #FADB8D;
        --secondary-500: #F8CC5E;

        --success-100: #B5F7D4;
        --success-300: #5AEE9E;
        --success-500: #15CB69;
        --warning-100: #F6F7B5;
        --warning-300: #EBEE5A;
        --warning-500: #C8CB15;
        --error-100: #F7B5BF;
        --error-300: #EE5A71;
        --error-500: #CB1531;
        --info-100: #B5E7F7;
        --info-300: #5ACAEE;
        --info-500: #15A0CB;

        --light: #FCFCFC;
        --gray-100: #E0E0E0;
        --gray-200: #C2C2C2;
        --gray-300: #A3A3A3;
        --gray-400: #8F8F8F;
        --gray-500: #7A7A7A;
        --gray-600: #666666;
        --gray-700: #474747; 
        --gray-800: #333333;
        --dark: #1A1A1A;

        --gap: 1rem;
        --border-radius: 0.5rem;

        --size-xs: 0.5rem;
        --size-sm: 0.75rem;
        --size-md: 1rem;
        --size-lg: 1.5rem;
        --size-xl: 2rem;

        --screen-xs: 0;
        --screen-sm: 36rem;
        --screen-md: 48rem;
        --screen-lg: 62rem;
        --screen-xl: 120rem;

        --max-width-xs: 100%;
        --max-width-sm: 100%;
        --max-width-md: 90%;
        --max-width-lg: 56.25rem;
        --max-width-xl: 67.5rem;

        --font-size-sm: 0.75rem;
        --font-size-md: 1rem;
        --font-size-lg: 1.25rem;
        --font-weight-light: 100;
        --font-weight-regular: 400;
        --font-weight-semibold: 600;
        --font-weight-bold: 700;
    }

    *, *::before, *::after {
        box-sizing: border-box;
    }

    body {
        margin: 0;
        font-family: 'Inter', 'IBM Plex Sans', BlinkMacSystemFont, -apple-system, 'Segoe UI', Helvetica, Arial, sans-serif;
        font-size: var(--font-size-md);
        font-weight: var(--font-weight-regular);
        --webkit-text-size-adjust: 100%;
        background: var(--light);
    }

    h1, h2, h3, h4, h5, h6 {
        margin-top: 0;
        margin-bottom: 1rem;
        padding-bottom: 1rem;
        font-family: 'IBM Plex Sans', 'Inter', BlinkMacSystemFont, -apple-system, 'Segoe UI', Helvetica, Arial, sans-serif;
        line-height: 2rem;
    }

    h1, h2, h3, h4, h5 {
        font-weight: var(--font-weight-bold);
    }

    h1 {
        font-size: 2rem;
        @media (min-width: 48rem){
            font-size: 3rem;
        }
    }

    h2 {
        font-size: 1.75rem;
        @media (min-width: 48rem) {
            font-size: 2rem;
        }
    }

    h3 {
        font-size: 1.5rem;
        @media (min-width: 48rem) {
            font-size: 1.75rem;
        }
    }

    h4 {
        font-size: 1.25rem;
        @media (min-width: 48rem) {
            font-size: 1.5rem;
        }
    }

    h5 {
        font-size: 1rem;
    }

    h6 {
        font-size: 1rem;
    }

    p {
        margin-top: 0;
        margin-bottom: 1rem;
    }

    a:active, a:focus, button:active, button:focus {
        outline: none;
        box-shadow: none;
        shadow: none;
    }

    img {
        width: 100%;
        vertical-align: middle;
        border-style: none;
    }

    svg {
        cursor: pointer;
    }
`;