import React from 'react';
import { Navigate, replace, Route, Routes, useLocation } from 'react-router-dom';

import ScrollToTop from './hooks/ScrollToTop';
import { GlobalStyles } from './components/GlobalStyles';

import Navbar from './components/features/Navbar';
import CustomFooter from './components/features/CustomFooter';

import Home from './pages/Home';
import Contact from './pages/Contact';

export default function App() {
  return (
    <>
      <GlobalStyles />
      <ScrollToTop />
      <Navbar />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      <CustomFooter />
    </>
  );
}