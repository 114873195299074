import React from 'react';

import { Container } from '../components/layout/Container';
import { Section } from '../components/layout/Section';
import { SectionHeader } from '../components/shared/SectionHeader';

export default function Home() {
    return (
        <>
            <SectionHeader bg="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_auto,dpr_auto/r-architecture-2gDwlIim3Uw-unsplash_ypayrw.webp" />
            <Container>
                <Section>
                    <h1>About Lobos Realty Investments</h1>
                    <p>
                        Lobos Realty Investments specializes in purchasing properties for cash and in their current condition. We understand that selling a property can be a stressful and 
                        time-consuming process which is why we strive to make the experience as smooth and hassle-free as possible for our clients. With years of experience in the real estate 
                        industry and a team of dedicated professionals, we are able to offer fair and competitive prices for properties of all types.
                    </p>
                    <p>
                        Additionally, we are investor-friendly and have the resources to purchase multiple properties at a time. Our goal is to provide a quick and easy solution for those looking 
                        to sell their property without the burden of repairs or renovations. If you have a property you are interested in selling, don't hesitate to reach out to us for a no-obligation 
                        consultation.
                    </p>
                </Section>
            </Container>
        </>
    );
}